<template>
  <div class="teacher-statistic scroll">
    <div
      class="teacher-statistic__info info container"
      :class="{ 'mt-3': $route.name === 'Program' }"
    >
      <div class="info-left">
        <div v-if="$route.name === 'Program'" class="teacher-statistic__mode-toggler">
          <ToggleButton :isLeftPosition.sync="showStatAndStudents" />
        </div>
        <div class="teacher-statistic__title">{{ title }}</div>
        <transition name="component-fade" mode="out-in">
          <div class="teacher-statistic__subtitle">
            {{ statisticTitle }}
          </div>
        </transition>
      </div>
      <div class="info-right">
        <div class="teacher-statistic__close-button">
          <BigCloseButton @click="$emit('update:showStatistic', !showStatistic)" />
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <StatisticsStudents
          v-if="showStatAndStudents"
          :statisticMeta="statisticMeta"
          :showStatistic="showStatistic"
          :showStatisticDelayed="showStatisticDelayed"
        />
        <StatisticsAdminTable
          v-else
          :managerBanList="managerBanList"
          :showModalInviteAdmin="showModalInviteAdmin"
          :isManagerRole="isManagerRole"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import BigCloseButton from '@/components/Buttons/BigCloseButton.vue';
import ToggleButton from '@/components/Buttons/ToggleButton.vue';
import StatisticsStudents from '@/components/Statistics/StatisticsStudents.vue';
import StatisticsAdminTable from '@/components/Statistics/StatisticsAdminTable.vue';

export default {
  name: 'Statistic',
  props: {
    showStatistic: {
      type: Boolean,
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticMeta: {
      type: Object,
      default: () => ({}),
    },
    managerBanList: {
      type: Array,
      default: () => [],
    },
    showModalInviteAdmin: {
      type: Function,
      default: () => {},
    },
    isManagerRole: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    students: [],
    showStatAndStudents: true,
    showStatisticDelayed: false,
  }),
  components: {
    StatisticsAdminTable,
    StatisticsStudents,
    ToggleButton,
    BigCloseButton,
  },
  computed: {
    title() {
      if (this.statisticMeta?.type === 'course') {
        return this.$t('header.courseStatistics');
      }
      if (this.statisticMeta?.type === 'quiz') {
        return this.$t('header.testStatistics');
      }
      if (this.statisticMeta?.type === 'material') {
        return this.$t('header.materialStatistics');
      }
      if (this.statisticMeta?.type === 'video') {
        return this.$t('header.videoStatistics');
      }
      if (this.statisticMeta?.type === 'task') {
        return this.$t('header.taskStatistics');
      }
      return this.$t('header.programStatistics');
    },
  },
  watch: {
    showStatistic(bool) {
      // show table of users with delay 650ms on showing statistic page
      setTimeout(() => {
        this.showStatisticDelayed = bool;
      }, 650);
    },
  },
  beforeDestroy() {
    if (document.getElementById('mainLayout')) {
      document.getElementById('mainLayout').classList.remove('scrollHidden');
    }
  },
};
</script>
