<template>
  <div>
    <div class="lms-table__header">
      <div class="header__left" v-if="$route.name === 'Program'">
        <div class="select-all cursor-pointer" @click="handleSelectAll">
          <RadioButton
            isTypeCheckBox
            @change="handleSelectAll"
            :checked="isAllSelected"
            :isSending="!userCount"
          />
          <span>{{ $t('label.selectAll') }}</span>
        </div>
        <div
          class="manage-access"
          :class="{ 'manage-access--active': selected.length }"
          @click="handleMultipleBan"
        >
          <RedCrossCircleIcon :class="{ 'inactive-icon': !selected.length }" />
          <span>{{ $t('label.closeAccessToProgram') }}</span>
        </div>
        <div
          class="manage-access"
          :class="{ 'manage-access--active': selected.length }"
          @click="handleMultipleUnban"
        >
          <GreenFlagCircleIcon :class="{ 'inactive-icon': !selected.length }" />
          <span>{{ $t('label.openAccessToProgram') }}</span>
        </div>
      </div>
      <div class="header__right">
        <div
          class="download-answers-link"
          @click="downloadAnswers"
          v-if="statisticMeta.type === 'task'"
        >
          {{ $t('label.downloadAnswers') }}
          <DownloadIcon />
        </div>
        <StatDropdown
          v-if="statisticMeta.type !=='material' &&
            statisticMeta.type !=='video' &&
            statisticMeta.type !=='task'"
          :type="statisticMeta.type"
          :id="id"
        />
        <div class="download-stat" @click="downloadStat" v-else>
          {{ $t('label.downloadStatistics') }}
          <DownloadIcon />
        </div>
      </div>
    </div>
    <b-table
      class="lms-table program-statistic scroll"
      id="table-user"
      :items="getUsers"
      :fields="fields"
      :filter="activeWidgetSort"
      primary-key="username"
      borderless
      responsive
      selectable
      :busy.sync="loading"
      :tbody-transition-props="transProps"
      stacked="sm"
      :fixed="false"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-provider-paging="true"
      :current-page="currentPage"
      ref="table"
      @row-selected="onRowSelected"
    >
      <template #cell(checkbox)="data">
        <RadioButton isTypeCheckBox @change="handleUserSelect(data)" :checked="data.rowSelected" />
      </template>
      <template #cell(userNameAndLatName)="data">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
      <template #cell(status)="data">
        <div class="d-flex items-center align-items-center justify-content-between">
          <div class="mr-2 status-container">
            <ProgressStatus :status="status(data.item)" iconPosition="left" />
          </div>
        </div>
      </template>
      <template #cell(time)="data">
          {{secondsFormat(data.item.time_spent)}}
      </template>
      <template #cell(points)="data">
        {{ data.item.score }}/{{max_score}}
      </template>
      <template #cell(access)="data">
        <LmsSwitch
          :key="switcherKey"
          :value="!data.item.ban_status"
          size="large"
          withIcon
          @update:value="(bool) => handleBanSwitch(data, bool)"
        />
      </template>

      <!--      <template #table-busy>-->
      <!--        <div class="container f-center">-->
      <!--          <Loader />-->
      <!--        </div>-->
      <!--      </template>-->
    </b-table>
    <!--    <Pagination :limit="12" :numberOfPages="3" />-->
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination.vue';
import ActivityService from '@/services/activity.service';
import LmsSwitch from '@/components/LmsSwitch.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import RadioButton from '@/components/Buttons/RadioButton.vue';
import RedCrossCircleIcon from '@/components/Icons/RedCrossCircleIcon.vue';
import GreenFlagCircleIcon from '@/components/Icons/GreenFlagCircleIcon.vue';
import DownloadIcon from '@/components/Icons/DownloadIcon.vue';
import StatDropdown from '@/components/StatDropdown.vue';
import { ActivityStatuses } from '@/utils/statusConstants';
import {
  doing, done, notStarted, unavailable, rejected,
} from '@/utils/constants';
import { mapActions } from 'vuex';

export default {
  name: 'TableUsers',
  props: {
    id: {
      type: String,
      default: '',
    },
    activeWidgetSort: {
      type: String,
      default: 'All',
    },
    statisticMeta: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ProgressStatus,
    LmsSwitch,
    RadioButton,
    RedCrossCircleIcon,
    GreenFlagCircleIcon,
    DownloadIcon,
    StatDropdown,
  },
  // components: { Pagination },
  data() {
    return {
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: this.$route.name !== 'Program' ? 'd-none' : '',
          tdClass: this.$route.name !== 'Program' ? 'd-none' : '',
        },
        {
          key: 'userNameAndLatName',
          label: this.$t('label.user'),
        },
        {
          key: 'username',
          label: 'E-mail',
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('label.status'),
        },
        {
          key: 'time',
          label: this.$t('label.timeSpent'),
        },
        {
          key: 'points',
          label: this.$t('label.points'),
        },
        {
          key: 'access',
          label: this.$t('label.access'),
          thClass: this.$route.name !== 'Program' ? 'd-none' : '',
          tdClass: this.$route.name !== 'Program' ? 'd-none' : '',
        },
      ],
      selected: [],
      loading: false,
      sortBy: 'username',
      sortDesc: false,
      currentPage: 1,
      userCount: 0,
      isRequestBusy: false,
      max_score: 0,
      isAnswerFileBusy: false,
      isStatFileBusy: false,
      userBanList: [],
      switcherKey: 1,
    };
  },
  computed: {
    transProps() {
      // Transition name
      return this.activeWidgetSort === 'All' ? { name: 'flip-list' } : null;
    },
    isAllSelected() {
      return this.selected.length === this.userCount && this.userCount !== 0;
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    async getUsers(ctx) {
      const { programId } = this.$route.params;
      const id = this.id ? this.id : this.$route.params.programId;

      await ActivityService.getActivityById(id)
        .then(({ data }) => {
          this.max_score = data.max_score ?? 0;
          this.userBanList = data.student_ids_ban_list;
        })
        .catch(console.log);

      return ActivityService.getActivityStudentsStatistics({ programId, id })
        .then(({ data }) => {
          if (data) {
            // const allowedStudents = data.filter((item) => !banList.includes(item._id));
            const allowedStudents = data.map((element) => {
              const user = element;
              user.ban_status = !!this.userBanList.includes(user._id);
              return user;
            });
            this.$emit('allowedStudents', allowedStudents);
            const sortedUsers = this.sortItems(ctx, allowedStudents);
            this.userCount = sortedUsers.length;
            return sortedUsers;
          }
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    handleBanSwitch(user, data) {
      if (this.isRequestBusy) {
        return;
      }
      if (!data) {
        this.removeUser(user);
      } else {
        this.unbanUser(user);
      }
    },
    handleUserSelect(row) {
      if (!row.rowSelected) {
        this.$refs.table.selectRow(row.index);
      } else {
        this.$refs.table.unselectRow(row.index);
      }
    },
    async handleSelectAll() {
      if (this.selected.length !== this.userCount) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    removeUser(student) {
      const id = this.id ? this.id : this.$route.params.programId;
      const { programId } = this.$route.params;
      ActivityService.banMultipleStudents({
        programId,
        id,
        students: [student.item._id],
      })
        .then(() => {
          this.$refs.table.refresh();
          this.increaseSwitcherCounter();
        })
        .catch(this.errorMessage);
    },
    unbanUser(student) {
      const id = this.id ? this.id : this.$route.params.programId;
      const { programId } = this.$route.params;
      ActivityService.unbanMultipleStudents({
        programId,
        id,
        students: [student.item._id],
      })
        .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
          const isAllBanned = unbannedStudentsIds.length === 1;
          if (!isAllBanned) {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('header.attention'),
                body: this.$t('supportText.reachedUserLimit'),
              },
            });
          }
          this.$refs.table.refresh();
          this.increaseSwitcherCounter();
        })
        .catch(this.errorMessage);
    },
    errorMessage(error) {
      const errMessage = error?.response?.data?.error;
      this.setToaster({
        type: 'toast-warning',
        toast: {
          title: this.$t('errorMessages.somethingWentWrong'),
          body: errMessage || this.$t('errorMessages.tryAgain'),
        },
      });
    },
    fetchMultipleIds(param) {
      if (param === 'unban') {
        return this.selected
          .filter((element) => element.ban_status)
          .map((element) => element.student_id);
      }
      return this.selected
        .filter((element) => !element.ban_status)
        .map((element) => element.student_id);
    },
    handleMultipleBan() {
      const filteredIds = this.fetchMultipleIds('ban');
      if (filteredIds.length) {
        const id = this.id ? this.id : this.$route.params.programId;
        const { programId } = this.$route.params;
        this.isRequestBusy = true;
        ActivityService.banMultipleStudents({
          programId,
          id,
          students: filteredIds,
        })
          .then(() => {
            this.$refs.table.refresh();
            this.increaseSwitcherCounter();
            this.isRequestBusy = false;
          })
          .catch(this.errorMessage);
      } else {
        this.$refs.table.refresh();
        this.increaseSwitcherCounter();
        this.isRequestBusy = false;
      }
    },
    handleMultipleUnban() {
      const filteredIds = this.fetchMultipleIds('unban');
      if (filteredIds.length) {
        const id = this.id ? this.id : this.$route.params.programId;
        const { programId } = this.$route.params;
        this.isRequestBusy = true;
        ActivityService.unbanMultipleStudents({
          programId,
          id,
          students: filteredIds,
        })
          .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
            const isAllBanned = unbannedStudentsIds.length === filteredIds.length;
            if (!isAllBanned) {
              this.setToaster({
                type: 'toast-warning',
                toast: {
                  title: this.$t('header.attention'),
                  body: this.$t('supportText.notAllWasUnbaned'),
                },
              });
            }
            this.$refs.table.refresh();
            this.increaseSwitcherCounter();
            this.isRequestBusy = false;
          })
          .catch(this.errorMessage);
      } else {
        this.$refs.table.refresh();
        this.increaseSwitcherCounter();
      }
    },
    sortItems(ctx, items) {
      let filtered = items;
      if (ctx.filter === 'Done') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.done);
      } else if (ctx.filter === 'Doing') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.doing);
      } else if (ctx.filter === 'Not-started') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.notStarted);
      }
      return filtered.sort((a, b) => {
        if (ctx?.sortDesc) {
          return b.username.localeCompare(a.username);
        }
        return a.username.localeCompare(b.username);
      });
    },
    status(item) {
      if (item.ban_status) {
        return unavailable;
      }
      if (item.status === ActivityStatuses.done) {
        return done;
      }

      if (item.status === ActivityStatuses.rejected && this.statisticMeta?.type === 'quiz') {
        return {
          value: 'rejected',
          label: 'label.rejectedMasculine',
        };
      }

      if (item.status === ActivityStatuses.rejected) {
        return rejected;
      }

      if (item.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
    isSelected(user) {
      return this.selected.includes(user);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    downloadStat() {
      if (this.isStatFileBusy) {
        return;
      }
      this.isStatFileBusy = true;
      const { programId } = this.$route.params;
      ActivityService.getActivityStatisticsFile({
        programId,
        id: this.id,
        locale: this.$root.$i18n.locale,
      })
        .then((response) => {
          this.fetchBlobToXlsx(response.data, 'statistics.xlsx');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isStatFileBusy = false));
    },
    downloadAnswers() {
      if (this.isAnswerFileBusy) {
        return;
      }
      this.isAnswerFileBusy = true;
      const { programId } = this.$route.params;
      ActivityService.getActivityAnswersFile({
        programId,
        id: this.id,
        locale: this.$root.$i18n.locale,
      })
        .then((response) => {
          this.fetchBlobToXlsx(response.data, 'answers.xlsx');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isAnswerFileBusy = false));
    },
    increaseSwitcherCounter() {
      setTimeout(() => {
        this.switcherKey += 1;
      }, 300);
    },
    secondsFormat(seconds) {
      return new Date(seconds * 1000).toISOString().slice(11, 19);
    },
    fetchBlobToXlsx(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },
  },
};
</script>
