<template>
  <div class="statistic-card" :class="variant" @click="$emit('click')">
    <div class="statistic-card__header">
      <div class="title">{{ variantText }}</div>
      <div class="icon">
        <component :is="computedIcon" />
      </div>
    </div>
    <div class="statistic-card__progress-circle" v-if="variant !== 'All'">
      <svg class="progress-circle" xmlns="http://www.w3.org/2000/svg">
        <circle
          class="progress-circle-prog"
          :style="`stroke-dasharray: ${circlePercent};`"
          cx="38.06"
          cy="38.06"
          r="36.8"
        ></circle>
      </svg>
      <div class="percent">{{ showPercent }}%</div>
    </div>
    <div class="user-laptop" v-else>
      <img src="@/assets/images/svg/user-laptop.svg" alt="user looking at laptop" />
    </div>
    <div class="statistic-card__progress-counter">{{ counterSplit }}</div>
  </div>
</template>

<script>
import DoingIcon from '@/components/Icons/ProgressDoing.vue';
import DoneIcon from '@/components/Icons/ProgressDone.vue';
import NotStartedIcon from '@/components/Icons/ProgressNotStarted.vue';
import AllUsersIcon from '@/components/Icons/AllUsersIcon.vue';

export default {
  components: {
    DoingIcon,
    DoneIcon,
    NotStartedIcon,
    AllUsersIcon,
  },
  name: 'StatisticCard',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: 'Not-started',
    },
  },
  data: () => ({
    intervalPercent: false,
    intervalCount: false,
    showPercent: 0,
    showCount: 0,
  }),
  computed: {
    computedIcon() {
      switch (this.variant) {
        case 'Doing':
          return DoingIcon;
        case 'Done':
          return DoneIcon;
        case 'Not-started':
          return NotStartedIcon;
        case 'All':
          return AllUsersIcon;
        default:
          return DoingIcon;
      }
    },
    variantText() {
      if (this.variant === 'Doing') return this.$t('label.inProgress');
      if (this.variant === 'Done') return this.$t('label.gone');
      if (this.variant === 'All') return this.$t('label.totalListeners');
      return this.$t('label.didNotStart');
    },
    circlePercent() {
      return `${this.showPercent * 2.42} 999`;
    },
    counterSplit() {
      return Math.round(this.showCount).toLocaleString();
    },
  },
  watch: {
    percent: {
      handler(value) {
        clearInterval(this.intervalPercent);
        if (this.showPercent === value) {
          return;
        }
        this.intervalPercent = setInterval(() => {
          if (this.showPercent !== value) {
            let change = (value - this.showPercent) / 10;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            this.showPercent += change;
          }
        }, 20);
      },
      immediate: true,
    },
    count: {
      handler(value) {
        clearInterval(this.intervalCount);
        if (this.showCount === value) {
          return;
        }

        this.intervalCount = setInterval(() => {
          if (this.showCount !== value) {
            let change = (value - this.showCount) / 10;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            this.showCount += change;
          }
        }, 20);
      },
      immediate: true,
    },
  },
};
</script>
