<template>
  <div class="stat-dropdown" v-click-outside="closeDropdown">
    <div class="download-stat" @click="showDropdown = !showDropdown">
      {{ $t('label.downloadStatistics') }}
      <DownloadIcon />
    </div>
    <div class="stat-dropdown__dropdown" :class="{ show: showDropdown }">
      <div class="variants">
        <b-form-radio
          v-model="selected"
          v-for="item in radioItems"
          name="some-radios"
          :value="item.method"
          class="stat-item"
          :key="item.index"
        >
          {{ item.label }}
        </b-form-radio>
      </div>
      <div class="actions">
        <AddButton
          size="small"
          :title="$t('label.downloadStatistics')"
          v-if="!loading"
          @click="requestStatFile"
          :disabled="!selected"
        >
          <template #suffixIcon>
            <DownloadIcon class="ml-2" />
          </template>
        </AddButton>
        <div v-else class="dropdown-loader">
          <Loader disableScale />
          <div class="dropdown-loader__label">{{ $t('label.fileInProgress') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadIcon from '@/components/Icons/DownloadIcon.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import Loader from '@/components/Loader/Loader.vue';
import ActivityService from '@/services/activity.service';

const statisticsMode = {
  single: 'activity',
  withChildren: 'with children',
  recursively: 'recursively',
};

export default {
  name: 'StatDropdown',
  components: { DownloadIcon, AddButton, Loader },
  props: {
    type: {
      type: String,
      default: 'program',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showDropdown: false,
    selected: null,
    loading: false,
  }),
  computed: {
    radioItems() {
      if (this.type === 'program') {
        return [
          {
            label: this.$t('label.onlyProgram'),
            method: statisticsMode.single,
          },
          {
            label: this.$t('label.onlyProgramAndCourses'),
            method: statisticsMode.withChildren,
          },
          {
            label: this.$t('label.allProgramContent'),
            method: statisticsMode.recursively,
          },
        ];
      }
      if (this.type === 'course') {
        return [
          {
            label: this.$t('label.onlyCourse'),
            method: statisticsMode.single,
          },
          {
            label: this.$t('label.courseAndGroups'),
            method: statisticsMode.withChildren,
          },
          {
            label: this.$t('label.allCourseContent'),
            method: statisticsMode.recursively,
          },
        ];
      }
      return [
        {
          label: this.$t('label.quizOnly'),
          method: statisticsMode.single,
        },
        {
          label: this.$t('label.quizAndQuestions'),
          method: statisticsMode.withChildren,
        },
      ];
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    requestStatFile() {
      this.loading = true;
      const { programId } = this.$route.params;
      switch (this.selected) {
        case 'activity':
          ActivityService.getActivityStatisticsFile({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (this.loading = false));
          break;

        case 'with children':
          ActivityService.getActivityStatisticsFileWithChildren({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (this.loading = false));
          break;

        case 'recursively':
          ActivityService.getActivityStatisticsFileWithChildrenRecursively({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (this.loading = false));
          break;
        default:
      }
    },
    fetchBlobToXlsx(blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'statistics.xlsx');
      } else {
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        downloadLink.download = 'statistics.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },
    closeDropdown() {
      if (this.loading) {
        return;
      }
      this.showDropdown = false;
    },
  },
};
</script>
